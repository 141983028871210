<template>
  <div class="modal modal-sm active" :id="'modal-id-'+productId">
    <a @click="close()" class="modal-overlay" aria-label="Close"></a>
    <div class="modal-container">
      <div style="margin: 0.5rem; text-align: center">
        <span class="navbar-brand">intel for hire</span><br>
        <div class="text">Please enter your email address to receive the data and your credit card information.</div>
      </div>
      <form ref="paymentForm" class="purchase-form">
        <input type="text" ref="email" name="email" placeholder="Email address" class="purchase-input" autocomplete="email" />
        <div ref="card" id="card-element" class="loading">
          <!--Stripe Card Element-->
        </div>
        <button ref="payBtn" class="payBtn" type="submit">
          <div class="spinner hidden" ref="spinner"></div>
          <span ref="btnText">Pay {{price}}</span>
        </button>
        <p ref="cardError" role="alert" class="error"></p>
        <p class="text result-message hidden">Thank you for your order. We sent the data to your email.</p>
      </form>
      <div style="text-align: center;">
        <small><a @click="close()">Close</a></small>
      </div>
    </div>
  </div>
</template>
<style src="./../assets/stripe.css"></style>
<script>
import 'whatwg-fetch'
export default {
  name: 'PurchaseForm',
  props: {
    productId: String,
    price: String
  },
  data: function () {
    return {
      card: null
    }
  },
  mounted: function () {
    const self = this
    const purchase = {
      items: [{ id: self.productId }]
    }
    self.$refs.payBtn.disabled = true
    const request = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(purchase)
    }
    const paymentEndpoint = process.env.VUE_APP_STRIPE_PAYMENT_ENDPOINT
    fetch(paymentEndpoint, request)
      .catch(err => {
        showError(err)
      })
      .then(function (result) {
        // console.log(result)
        return result ? result.json() : false
      })
      .then(function (data) {
        if (!data) {
          return false
        }
        var style = {
          base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '13px',
            '::placeholder': {
              color: '#32325d'
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
        self.$refs.card.classList.remove('loading')
        const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
        const elements = stripe.elements()
        self.card = elements.create('card', { style: style })
        self.card.mount(self.$refs.card)
        self.card.on('change', function (event) {
          // Disable the Pay button if there are no card details in the Element
          self.$refs.payBtn.disabled = event.empty
          self.$refs.cardError.textContent = event.error ? event.error.message : ''
        })
        // var form = document.getElementById(`payment-form-${self.productId}`)
        self.$refs.paymentForm.addEventListener('submit', function (event) {
          // console.log(event)
          event.preventDefault()
          payWithCard(stripe, self.card, data.clientSecret) // Complete when the submit button is clicked
        })
      })
    const payWithCard = function (stripe, card, clientSecret) {
      const email = self.$refs.email.value
      if (!email) {
        alert('Enter a valid email to receive your purchase!')
        return false
      }
      const linkEndpoint = process.env.VUE_APP_DATA_DELIVERY_ENDPOINT
      const payload = { email: email, productId: self.productId }
      const request = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
      loading(true)
      stripe
        .confirmCardPayment(clientSecret, {
          receipt_email: email,
          payment_method: {
            card: card
          }
        })
        .then(function (resPayment) {
          if (resPayment.error) {
            showError(resPayment.error.message)
          } else {
            // GENERATE SIGNED URL HERE AND SHOW THE LINK BEFORE ORDERCOMPLETE
            fetch(linkEndpoint, request)
              .catch(err => {
                // console.log(err)
                showError(err)
              })
              /* .then(function (resLink) {
                // console.log(resLink)
                return resLink.json()
              }) */
              .then(function (data) {
                // console.log(data)
                orderComplete(resPayment.paymentIntent.id)
              })
          }
        })
    }
    /* ------- UI helpers ------- */
    // Shows a success message when the payment is complete
    const orderComplete = function (paymentIntentId) {
      loading(false)
      // document
      //  .querySelector('.result-message a')
      //  .setAttribute(
      //    'href',
      //    link
      // 'https://dashboard.stripe.com/test/payments/' + paymentIntentId
      //  )
      document.querySelector('.result-message').classList.remove('hidden')
      self.$refs.payBtn.disabled = true
    }
    // Show the customer the error from Stripe if their card fails to charge
    const showError = function (errorMsgText) {
      loading(false)
      var errorMsg = self.$refs.cardError
      errorMsg.textContent = errorMsgText
      setTimeout(function () {
        errorMsg.textContent = ''
      }, 4000)
    }
    const loading = function (isLoading) {
      if (isLoading) { // Disable the button and show a spinner
        self.$refs.payBtn.disabled = true
        self.$refs.spinner.classList.remove('hidden')
        self.$refs.btnText.classList.add('hidden')
      } else {
        self.$refs.payBtn.disabled = false
        self.$refs.spinner.classList.add('hidden')
        self.$refs.btnText.classList.remove('hidden')
      }
    }
  },
  methods: {
    close: function () {
      this.$emit('closing', this.productId)
      this.$destroy()
      // this.$el.parentNode.removeChild(this.$el)
    }
  }
}

</script>
<style scoped>
#userEmail {
  background-color: #fff !important;
}

.text {
  margin-top: 0.5rem;
  color: #32325d;
  font-family: Arial, sans-serif;
  font-smoothing: antialiased;
  font-size: 13px;
  line-height: 13px;
}

.error {
  color: red;
  font-family: Arial, sans-serif;
  font-smoothing: antialiased;
  font-size: 13px;
  line-height: 13px;
}

.result-message {
  color: green;
}

</style>
