<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title hide-sm"> Technology and Innovation Research Analyst</h1>
          <h2 class="title show-sm"> Technology and Innovation Research Analyst</h2>
          <br>
          <!-- <img src="/img/teambg.svg" class="illustration"> -->
          <p>Intel for hire, Inc., a California-based company, is looking for a talented and resourceful Technology and Innovation Research Analyst in Argentina or elsewhere in South America.</p>
          <img src="/img/analystbg.svg" class="illustration">
          <div class="section-spacer"></div>
          <h3>The opportunity</h3>
          <p>When you join Intel for hire, you’ll have the opportunity to work on research projects for the most sophisticated startups, established companies and enterprise customers in Silicon Valley and the rest of the world. Moreover, you’ll join, remotely and full time, a small, hardworking and friendly team that values curiosity, initiative and intellectual courage.</p>
          <p>Our job is to help these companies make good decisions with our on-demand research services. We generally research new markets, industries, emerging technologies, startups and other tech companies. If you are genuinely interested in startups, technology and innovation, this job is for you. Moreover, our customers love our data-driven research process, so you’ll have the chance to help them (and us) change the world if you love challenging projects that involve both qualitative and quantitative analysis.</p>
          <p>You’ll also have the opportunity to do research and analysis in a whole range of tech verticals and technology applications. For example, we have done research on renewable energy, enterprise software, healthcare, internet of things (IoT), and virtual and augmented reality, among many others. Customers generally need quick turnarounds so it’s very important to be able to get up to speed really quickly when we explore new industry verticals.</p>
          <p>Projects may involve, among other diverse tasks:</p>
          <ul>
            <li>Researching the web and premium data sources, or using our proprietary software, to identify companies that meet specific requirements</li>
            <li>Mapping a competitive landscape to understand the strategic position of companies</li>
            <li>Investigating companies to understand their products, services and value proposition</li>
            <li>Doing systematic reviews of third-party reports, patent documents, academic research and other publications to understand trends and main drivers in science and industry verticals</li>
          </ul>
          <div class="section-spacer"></div>
          <h3>Role requirements</h3>
          <p>You’ll be comfortable in this job if you are detail-oriented, organize your work independently, communicate well, excel at meeting due dates and possess the following knowledge/skills:</p>
          <ul>
            <li>Demonstrated ability and/or 1+ year experience conducting research and using quantitative and qualitative methods to analyze emerging technologies, market trends, startups and other tech companies</li>
            <li>Experience working with Google Office (Docs, Sheets, Slides), MS Office (Word, Excel y Powerpoint) and web browsers</li>
            <li>Spanish working proficiency, English reading and writing proficiency are a must</li>
            <li>Preferred: degree in Management, Economics or Engineering and demonstrated data wrangling skills (not required)</li>
          </ul>
          <div class="section-spacer"></div>
          <h3>Highlights</h3>
          <p>This role includes:</p>
          <ul>
            <li>Work on projects that support innovation in Silicon Valley and around the world</li>
            <li>Flexible schedule and work from home</li>
            <li>Paid time to learn and further develop your skills</li>
            <li>A competitive pay based on experience</li>
            <li>Opportunity to grow and take on more responsibilities as our company grows</li>
          </ul>
          <div class="section-spacer"></div>
          <h3>How to apply</h3>
          <p>You may start at our <a @click="$router.push('/')">home page</a> to learn more about our company and reach out to Luciano Kay, Ph.D. at <a href="mailto:lucho@intelforhire.com">lucho@intelforhire.com</a> to discuss compensation and other questions you may have. Please enclose a copy of your resume. We look forward to hearing from you!</p>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="" @click="$router.push('/')">Go back to the home page &rarr;</button>
          <div class="section-spacer"></div>
          <!-- footer -->
          <div class="footer">
            <div class="divider"></div>
            <div class="section-spacer"></div>
            <div class="columns">
              <div class="column col-lg-12 col-6">
                <div class="col-container">
                  <p><strong>Address</strong></p>
                  <p>Intel for hire, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>United States</p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                  <p><strong>Company</strong></p>
                  <p>
                    <router-link to="/about">About</router-link>
                  </p>
                  <p><a href="mailto:lucho@intelforhire.com">Email</a></p>
                  <p><a href="https://twitter.com/IntelForHire" target="_blank">Twitter</a></p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                </div>
              </div>
            </div>
            <div class="section-spacer"></div>
            <p>© 2021 Intel for hire, Inc. Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a>.</p>
          </div>
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Analyst',
  metaInfo: () => ({
    title: 'Intel for hire :: Technology and Innovation Research Analyst',
    // titleTemplate: '%s | Vue Meta Examples',
    meta: [
      { property: 'og:title', content: 'Technology and Innovation Research Analyst', vmid: 'og:title' },
      { property: 'og:description', content: 'California-based Intel for hire, Inc. is looking for a talented and resourceful analyst in Argentina or elsewhere in South America.', vmid: 'og:description' },
      { property: 'og:url', content: `${document.location}`, vmid: 'og:url' },
      { property: 'og:image', content: `https://intelforhire.com/mg/meta-analyst.png`, vmid: 'og:image' },
    ]
  }),
/*  title: function () {
    return 'Intel for hire :: Technology and Innovation Research Analyst'
  },
  meta: function () {
    return {
      title: 'Technology and Innovation Research Analyst',
      description: 'Intel for hire, Inc., a California-based company, is looking for a talented and resourceful Technology and Innovation Research Analyst in Argentina or elsewhere in South America.',
      url: `${document.location}`,
      image: `${document.location}/img/meta-analyst.png`
    }
  },*/
  components: {
    // HelloWorld
  }
}

</script>
<style type="text/css" scoped>
ul {
  text-indent: -20px;
  margin-left: 20px;
}
li::marker {
  content: "👉🏻";
}
</style>
