<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title hide-sm"> Let's talk and<br>see how we can help</h1>
          <h2 class="title show-sm"> Let's talk and see how we can help</h2>
          <br>
          <p>Reach out today to discuss your project and see how we can help. We'll schedule a call and tell you more about our experience and results.</p>
          <br>
          <p>Email:<br><a href="mailto:lucho@intelforhire.com">lucho@intelforhire.com</a></p>
          <p>Mailing address:<br>Intel for hire, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>USA</p>
          <p>On Twitter:<br><a href="https://twitter.com/IntelForHire" target="_blank">@intelforhire</a></p>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push('about')">Learn more about us &rarr;</button><br>
          <button class="btn btn-sm my-2" style="" @click="$router.push('/')">Go back to the home page &rarr;</button>
          <div class="section-spacer"></div>
          <div class="section-spacer"></div>
          <small>Copyright © 2021 Intel for hire, Inc.</small>
          <div class="section-spacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Contact',
  metaInfo: () => ({
    title: 'Intel for hire :: Contact'
  }),
/*  title: function () {
    return 'Intel for hire :: Contact'
  },*/
  components: {
    // HelloWorld
  }
}

</script>
