<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title">Reliable data and insights to make your next decision.</h1>
          <p>We deliver on-demand research to help innovators, startups and other tech companies make good decisions. Contact us if you need to learn about companies, industries, market verticals or emerging technologies.</p>
          <br>
          <button class="btn btn-primary mt-2" style="margin-right: 13px; width: 200px" @click="$router.push('/#contact')">Contact us</button>
          <button class="btn btn-secondary mt-2" style="width: 200px" @click="$router.push('about')">Learn more</button>
          <br><br>
          <!-- <div class="section-spacer"></div> -->
          <div class="columns">
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/processbg.svg" class="illustration">
                <h4>Robust methods and analysis</h4>
                <p>We draw on world-class training and skills and an efficient research process that we keep improving as we gain more and more experience.</p>
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/actionablebg.svg" class="illustration">
                <h4>Streamlined, practical results</h4>
                <p>We strive to deliver laser-focused, data-based results that add value to your business and help you make good decisions.</p>
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/perspectivebg.svg" class="illustration">
                <h4>An objective, informed perspective</h4>
                <p>Our diverse industry, technology and type of project experience gives us a valuable, objective and evidence-based perspective.</p>
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/advicebg.svg" class="illustration">
                <h4>A reliable source of insights</h4>
                <p>We’re easy to communicate with and shine at meeting due dates. We adhere to the highest ethical and confidentiality standards.</p>
              </div>
            </div>
          </div>
          <div class="section-spacer"></div>
          <!-- -->
          <div class="carrousel">
            <div class="carrousel-title">Trusted by startups, corporations and academic teams</div>
            <input type="radio" name="slides" id="radio-1" checked>
            <input type="radio" name="slides" id="radio-2">
            <input type="radio" name="slides" id="radio-3">
            <input type="radio" name="slides" id="radio-4">
            <input type="radio" name="slides" id="radio-5">
            <input type="radio" name="slides" id="radio-6">
            <ul class="slides">
              <li class="slide">
                <p>
                  <q>Exactly what we were looking for in our project. Quality products, I highly recommend for any scope of project.</q>
                  <span class="author">
                    Caitlin Parks<br>CACI International, Inc.
                  </span>
                </p>
              </li>
              <li class="slide">
                <p>
                  <q>They quickly understood the technical infrastructure of our product offering and quickly delivered the research.</q>
                  <span class="author">
                    Michael Martel<br>Maverick IQ, LLC.
                  </span>
                </p>
              </li>
              <li class="slide">
                <p>
                  <q>We are very happy with the work performed for us. Guided us on what we really needed then delivered it.</q>
                  <span class="author">
                    Matt Fleck<br>Anonomatic, Inc.
                  </span>
                </p>
              </li>
              <li class="slide">
                <p>
                  <q>Exceptionally thorough, above and beyond expectations in a tight turn-around timeline.</q>
                  <span class="author">
                    Brian Barbaglia<br>Upwork Global, Inc.
                  </span>
                </p>
              </li>
              <li class="slide">
                <p>
                  <q>Incredibly knowledgeable and effective. Highly recommended if you need a true professional.</q>
                  <span class="author">
                    Nicola Bianchi<br>Kellogg School of Management, Northwestern University
                  </span>
                </p>
              </li>
              <li class="slide">
                <p>
                  <q>Great to work with. Turnaround time and work output was also exceptional. Highly recommended.</q>
                  <span class="author">
                    Prem Mohanty<br>Benchling, Inc.
                  </span>
                </p>
              </li>
            </ul>
            <div class="slidesNavigation">
              <label for="radio-1" id="dotForRadio-1"></label>
              <label for="radio-2" id="dotForRadio-2"></label>
              <label for="radio-3" id="dotForRadio-3"></label>
              <label for="radio-4" id="dotForRadio-4"></label>
              <label for="radio-5" id="dotForRadio-5"></label>
              <label for="radio-6" id="dotForRadio-6"></label>
            </div>
          </div>
          <div class="section-spacer"></div>
          <h3>Our experience</h3>
          <p>We have experience in a whole range of use cases, tech verticals and technology applications and we are really quick to get up to speed when we explore or focus on new spaces. The common use cases of our research include competitive analysis, market and industry research, innovation research, tech scouting, and academic research.</p>
          <button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push('about')">Learn more about us &rarr;</button>
          <div class="section-spacer"></div>
          <a id="contact" name="contact"></a>
          <h3>Contact us</h3>
          <p>Get in touch via email at <a href="mailto:lucho@intelforhire.com">lucho@intelforhire.com</a> if you'd like to learn more about us or discuss a new project.</p>
          <div class="section-spacer"></div>
          <!-- footer -->
          <div class="footer">
            <div class="divider"></div>
            <div class="section-spacer"></div>
            <div class="columns">
              <div class="column col-lg-12 col-6">
                <div class="col-container">
                  <p><strong>Address</strong></p>
                  <p>Intel for hire, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>United States</p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                  <p><strong>Company</strong></p>
                  <p>
                    <router-link to="/about">About</router-link>
                  </p>
                  <p><a href="mailto:lucho@intelforhire.com">Email</a></p>
                  <p><a href="https://twitter.com/IntelForHire" target="_blank">Twitter</a></p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                </div>
              </div>
            </div>
            <div class="section-spacer"></div>
            <p>© 2021 Intel for hire, Inc. Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a>.</p>
          </div>
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
/*  title: function () {
    return 'Intel for hire :: Home'
  },*/
  metaInfo: () => ({
    title: 'Intel for hire :: Home'
  }),
  components: {}
}

</script>
<style lang="css">
.client {
  margin-top: 0.3rem;
  font-weight: 500;
  font-size: 90%;
}

/*Carousel*/
/*https://codeconvey.com/pure-css-testimonial-slider/*/
.carrousel {
  background: #F1F1FC;
  text-align: center;
  padding: 2rem 0.5rem;
  border-radius: 0.3rem;
  /*height: 7.5em;*/
  /*max-width: 750px;*/
  /*margin: auto;*/
  margin-left: -.4rem;
  margin-right: -.4rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.carrousel h2 {
  margin: 0;
  margin-top: -1.7em;
  padding: 10px;
  font-size: 1em;
  text-align: center;
  color: #564f4f;
}

.carrousel-title {
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.2rem;
  padding: 0 0.3rem;
  letter-spacing: -0.02rem;
}

.carrousel .slides {
  width: 600%;
  left: 0;
  padding-left: 0;
  padding-top: 1rem;
  overflow: hidden;
  list-style: none;
  position: relative;
  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  margin-bottom: 0 !important;
}

.carrousel .slides li {
  width: 16.6667%;
  position: relative;
  float: left;
}

.carrousel li p {
  margin-top: 0;
}

.carrousel li q {
  max-width: 90%;
  display: block;
  margin: auto;
  color: #4C5059;
  font-size: 0.9rem !important;
  font-family: Inter, sans-serif;
}

.carrousel li span.author {
  margin-top: 0.5em;
  display: block;
  color: #75797F;
  font-size: 0.85rem !important;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.carrousel .slidesNavigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 1em;
  /*--- Centering trick---*/
  /* Absolute positioning*/
  position: absolute;
  /* Abosulte positioning*/
  width: 170px;
  /*This width  is the addition of the width of all the navigations dots - So in this case is   104 because the navigation dots are 26px (width:10px and 6px marginleft + 6 px marginright) and there are 4 dots so 4x26=104 */
  left: 50%;
  /*Centering de element*/
  margin-left: -85px;
  /*adjusting the centering by applying a negative margin of half of the width*/
}

.carrousel input {
  display: none;
}

.carrousel .slidesNavigation label {
  float: left;
  margin: 6px;
  display: block;
  height: 15px;
  width: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 2px #5755D9;
  font-size: 0;
}

/* You have to repeat this with each slide
TODO: make it easier with SCSS
25% movement 100/slides-num
*/
#radio-1:checked~.slides {
  transform: translateX(0%);
}

#radio-2:checked~.slides {
  transform: translateX(-16.6667%);
}

#radio-3:checked~.slides {
  transform: translateX(-33.3334%);
}

#radio-4:checked~.slides {
  transform: translateX(-50%);
}

#radio-5:checked~.slides {
  transform: translateX(-66.6667%);
}

#radio-6:checked~.slides {
  transform: translateX(-83.3334%);
}

.carrousel .slidesNavigation label:hover {
  cursor: pointer;
}

/* You have to repeat this with each slide/dot */
.carrousel #radio-1:checked~.slidesNavigation label#dotForRadio-1,
.carrousel #radio-2:checked~.slidesNavigation label#dotForRadio-2,
.carrousel #radio-3:checked~.slidesNavigation label#dotForRadio-3,
.carrousel #radio-4:checked~.slidesNavigation label#dotForRadio-4,
.carrousel #radio-5:checked~.slidesNavigation label#dotForRadio-5,
.carrousel #radio-6:checked~.slidesNavigation label#dotForRadio-6 {
  background: #5755D9;
}

@media (max-width: 796px) {
  .carrousel {
    /*height: 8.5em;*/
  }
}

@media (max-width: 480px) {
  .carrousel li p {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .carrousel li q {
    font-size: 1.1em;
  }

  /*  .carrousel li img {
    width: 2em;
    margin-left: -1em;
    margin-right: 0.25em;
  }*/
}

</style>
