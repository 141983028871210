import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Developer from '@/views/Developer.vue'
import Analyst from '@/views/Analyst.vue'
import Data from '@/views/Data.vue'
import Contact from '@/views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/data', name: 'Data', component: Data },
  { path: '/about', name: 'About', component: About },
  { path: '/careers/developer', name: 'Developer', component: Developer },
  { path: '/careers/analyst', name: 'Analyst', component: Analyst },
  { path: '/contact', name: 'Contact', component: Contact }
]

const router = new VueRouter({
  mode: 'history',
  base: '/', // process.env.BASE_URL,
  /*  linkActiveClass: 'is-active',
    linkExactActiveClass: 'is-exact-active', */
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      // console.log('going up')
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
