<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title hide-sm"> We obtain the data, connect the dots and answer your questions</h1>
          <h2 class="title show-sm"> We obtain the data, connect the dots and answer your questions</h2>
          <br>
          <img src="/img/teambg.svg" class="illustration">
          <p>Intel for hire does on-demand research and analysis to inform decision making. We get your inputs, gather and analyze relevant data, and deliver answers to your questions in the format of your choice: slides, spreadsheets, reports or an evidence-based, objective opinion.</p>
          <div class="section-spacer"></div>
          <h3>Our focus</h3>
          <p>We generally research new markets, industries, emerging technologies and the startups and tech companies that build them. Our projects go from desk research to more sophisticated analysis in the form of deep dives or in-depth research. We work with startups and other tech companies but also with enterprise companies and other organizations in a whole range of tech verticals.</p>
          <div class="section-spacer"></div>
          <h3>Our process</h3>
          <p>In every case, we strive to deliver the most robust research and analysis. We draw on quantitative, qualitative and mixed methods that we’ve learned and developed in years of academic work with leading universities and research consulting experience in management, innovation and economics. But, more importantly, we have developed, and keep improving, a research process for this specific kind of research to make results more valuable to you.</p>
          <div class="section-spacer"></div>
          <h3>The data</h3>
          <p>We use secondary, open and premium data sources and use both structured and unstructured data. This includes, for example, company websites, marketing collateral, patents & trademarks, scientific publications, white papers, company public filings, news sources and specialized media, company and startup databases and APIs.</p>
          <p>In some cases, we do primary data gathering and obtain deep insights from folks who have much more experience than us in industry-specific areas. Much of this knowledge is increasingly found codified in secondary data sources though.</p>
          <div class="section-spacer"></div>
          <h3>Our experience</h3>
          <p><a href="http://www.kay.com.ar" target="_blank">Luciano Kay</a> runs Intel for hire. He worked for more than 15 years on research, evaluation and consulting projects with leading universities, companies and other organizations. He's a published author – <a href="https://www.amazon.com/gp/product/1781006474/" target="_blank">here's his book on the Google Lunar X Prize</a> – and obtained Ph.D. and Licentiate degrees in economic development policy and management.</p>
          <p>Since we started in early 2020, we have become a small but resourceful team that has done research for a growing number of startups, tech companies, enterprise companies, universities and other organizations.</p>
          <p>Our years long experience includes:</p>
          <p><strong>Industries / verticals</strong> – Aerospace, Healthcare, SDoH Data, Biotech, Pharma, Renewable Energy, Enterprise Analytics, Modeling Software, Cybersecurity, Marketing & Sales, Human Resources, Content Management, Cloud Services, Internet of Things (IoT), Venture Capital &amp; Accelerators, Sustainable Textiles &amp; Clothing, Electrical &amp; Lighting, Shipbuilding, Mining, Steelmaking, Furniture, Metal Mechanic, Pulp and Paper.</p>
          <p><strong>Technologies / applications</strong> – Nanotechnology, New Materials, Synthetic Biology, New Drug Development, Cloud Storage, Clean Energy Sources, Energy Storage, Building Energy Efficiency, Artificial Intelligence, Lunar Payload Delivery, Autonomous Vehicles, Alternative Meats, Beauty Tech, Mobile Apps, Commercial Sensors, Virtual and Mixed Reality, Social Tech.</p>
          <p><strong>Geographies</strong> – Global, regional and country-level including, for example, U.S. (and states such as California, New York and Georgia or cities such as New York), Germany, Spain, Northern Ireland, New Zealand, Japan, China, Malaysia, Argentina, Mexico, Brazil, Chile, Uruguay.</p>
          <p><strong>Organizations</strong> – Startups, SMB, Enterprise, Government Agencies, Professional Associations, Accelerators, Publicly Traded Companies.</p>
          <div class="section-spacer"></div>
          <h3>Questions?</h3>
          <p>Get in touch via email at <a href="mailto:lucho@intelforhire.com">lucho@intelforhire.com</a> if you'd like to know more about us.</p>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="" @click="$router.push('/')">Go back to the home page &rarr;</button>
          <div class="section-spacer"></div>
          <!-- footer -->
          <div class="footer">
            <div class="divider"></div>
            <div class="section-spacer"></div>
            <div class="columns">
              <div class="column col-lg-12 col-6">
                <div class="col-container">
                  <p><strong>Address</strong></p>
                  <p>Intel for hire, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>United States</p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                  <p><strong>Company</strong></p>
                  <p>
                    <router-link to="/about">About</router-link>
                  </p>
                  <p><a href="mailto:lucho@intelforhire.com">Email</a></p>
                  <p><a href="https://twitter.com/IntelForHire" target="_blank">Twitter</a></p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                </div>
              </div>
            </div>
            <div class="section-spacer"></div>
            <p>© 2021 Intel for hire, Inc. Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a>.</p>
          </div>
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'About',
  metaInfo: () => ({
    title: 'Intel for hire :: About us'
  }),
/*  title: function () {
    return 'Intel for hire :: About us'
  },*/
  components: {
    // HelloWorld
  }
}

</script>
