<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title"><span class="hide-sm"> data</span></h1>
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h3>Recently-funded Latin American startups</h3>
          <p>Includes 12 companies that raised funding in December 2020 and the following attributes: Company name, Website, Industry, Location, Funding Type, Funding Amount, Funding Date, Company LinkedIn.</p>
          <p>Date: January 13, 2021</p>
          <p>Format: downloadable CSV/Excel data</p>
          <div>
            <p>
              <a @click="showForm['IFH-002-LATAMSTARTUPS-DEC2020']=true">Purchase the data</a>
            </p>
            <PurchaseForm v-if="showForm['IFH-002-LATAMSTARTUPS-DEC2020']" :productId="'IFH-002-LATAMSTARTUPS-DEC2020'" :price="'US$14.95'" @closing="hideForm" />
          </div>
          <div class="section-spacer"></div>
          <h3>Women's health tech startups</h3>
          <p>Includes 19 companies in pre care, fertility and period tracking solutions and the following attributes: Company name, Website, Description, Care Phase, Health Focus, Technology, Technology Details, Legal Name, HQ Location, CEO Name, Year Founded, Total Funding, Latest Funding Type, Revenue, Employees, Company LinkedIn.</p>
          <p>Date: December 30, 2020</p>
          <p>Format: downloadable CSV/Excel data</p>
          <div>
            <p>
              <a @click="showForm['IFH-001-WOMENPRECARE']=true">Purchase the data</a>
            </p>
            <PurchaseForm v-if="showForm['IFH-001-WOMENPRECARE']" :productId="'IFH-001-WOMENPRECARE'" :price="'US$14.95'" @closing="hideForm" />
          </div>
          <div class="section-spacer"></div>
          <a href="#data"></a>
          <h3>About these datasets</h3>
          <p>These dataset generally compile publicly available data but we take the time to validate each data point to the best of our efforts. In addition, we go over company materials to include data attributes that are generally not available from other sources.</p>
          <div class="section-spacer"></div>
          <h3>Need a different dataset?</h3>
          <p>Reach out today to discuss your project and see how we can help.</p>
          <button class="btn btn-primary" @click="$router.push('contact')">Get in touch to discuss your project</button>
          <div class="section-spacer"></div>
          <div class="section-spacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PurchaseForm from '@/components/PurchaseForm.vue'
export default {
  name: 'Data',
  components: {
    PurchaseForm
  },
  data: function () {
    return {
      showForm: {
        'IFH-002-LATAMSTARTUPS-DEC2020': false,
        'IFH-001-WOMENPRECARE': false
      }
    }
  },
  methods: {
    hideForm: function (productId) {
      this.showForm[productId] = false
    }
  }
}

</script>
