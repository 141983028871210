<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <header class="navbar">
            <section class="navbar-header">
              <span>
                <a href="/" class="navbar-brand">intel for hire</a>
              </span>
            </section>
            <section class="navbar-section">
            </section>
          </header>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="title hide-sm"> Full Stack Developer<br>(with data skills)</h1>
          <h2 class="title show-sm"> Full Stack Developer<br>(with data skills)</h2>
          <br>
          <!-- <img src="/img/teambg.svg" class="illustration"> -->
          <p>Intel for hire, Inc., a California-based company, is looking for a talented, resourceful, junior-level Full Stack Developer (with some data skills) in Argentina or elsewhere in South America.</p>
          <img src="/img/codingbg.svg" class="illustration">
          <div class="section-spacer"></div>
          <h3>The opportunity</h3>
          <p>When you join Intel for hire, you’ll have the opportunity to help us build the tools we use to serve the most sophisticated startups, established companies and enterprise customers in Silicon Valley and the rest of the world. Moreover, you’ll join, remotely and full time, a small, hardworking and friendly team that values curiosity, initiative and intellectual courage.</p>
          <p>Our job is to help these companies make good decisions with our on-demand research services. The databases and software tools we build and use internally help us research new markets, industries, emerging technologies, startups and other tech companies. Our customers love our technology-enabled research process, so you’ll help them (and us) change the world if you can quickly turn ideas into new tools.</p>
          <p>When you join us, you’ll use the latest cloud and software technologies to build and maintain our tools, which may include crawlers to obtain data from hundreds of thousands of websites, applications that automate the analysis of millions of patent documents and team-facing web apps that make our work more efficient, to name a few examples.</p>
          <div class="section-spacer"></div>
          <h3>Role requirements</h3>
          <p>The technologies we work with may include:</p>
          <p><strong>Cloud</strong>: a broad range of Amazon Web Services (AWS) technologies, including Serverless apps, Lambda and Step functions (NodeJS and/or Python), RDS (MySql), DynamoDB, S3 and CloudFront, to name just a handful of them. Occasionally, we also use Google Cloud Platform (GCP)’s BigQuery, Google Workspace APIs.</p>
          <p><strong>Browser</strong>: Javascript (sometimes Typescript), HTML and CSS, VueJS, Chrome extensions.</p>
          <p>You’ll be comfortable working with us if you organize your work independently, communicate well, love taking on challenging projects and possess the following knowledge/skills:</p>
          <ul>
            <li>Demonstrated ability and/or 1+ year experience building web app and/or backend solutions</li>
            <li>Demonstrated ability to extract, transform and load data into our databases</li>
            <li>Spanish working proficiency and English writing skills (documentation and internal communications)</li>
            <li>Preferred: a degree in Software Engineering, Information Technology or Computer Sciences (not required)</li>
          </ul>
          <div class="section-spacer"></div>
          <h3>Highlights</h3>
          <p>This role includes:</p>
          <ul>
            <li>Work on projects that support innovation in Silicon Valley and around the world</li>
            <li>Flexible schedule and work from home</li>
            <li>Paid time to learn and further develop your skills</li>
            <li>A competitive pay based on experience</li>
            <li>Opportunity to grow and take on more responsibilities as our company grows</li>
          </ul>
          <div class="section-spacer"></div>
          <h3>How to apply</h3>
          <p>You may start at our <a @click="$router.push('/')">home page</a> to learn more about our company and reach out to Luciano Kay, Ph.D. at <a href="mailto:lucho@intelforhire.com">lucho@intelforhire.com</a> to discuss compensation and other questions you may have. Please enclose a copy of your resume. We look forward to hearing from you!</p>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="" @click="$router.push('/')">Go back to the home page &rarr;</button>
          <div class="section-spacer"></div>
          <!-- footer -->
          <div class="footer">
            <div class="divider"></div>
            <div class="section-spacer"></div>
            <div class="columns">
              <div class="column col-lg-12 col-6">
                <div class="col-container">
                  <p><strong>Address</strong></p>
                  <p>Intel for hire, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>United States</p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                  <p><strong>Company</strong></p>
                  <p>
                    <router-link to="/about">About</router-link>
                  </p>
                  <p><a href="mailto:lucho@intelforhire.com">Email</a></p>
                  <p><a href="https://twitter.com/IntelForHire" target="_blank">Twitter</a></p>
                  <br>
                </div>
              </div>
              <div class="column col-lg-12 col-3">
                <div class="col-container">
                </div>
              </div>
            </div>
            <div class="section-spacer"></div>
            <p>© 2021 Intel for hire, Inc. Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a>.</p>
          </div>
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Developer',
  metaInfo: () => ({
    title: 'Intel for hire :: Full Stack Developer (with data skills)',
    // titleTemplate: '%s | Vue Meta Examples',
    meta: [
      { property: 'og:title', content: 'Full Stack Developer (with data skills)' },
      { property: 'og:description', content: 'California-based Intel for hire, Inc. is looking for a talented, resourceful developer in Argentina or elsewhere in South America.' },
      { property: 'og:url', content: `${document.location}` },
      { property: 'og:image', content: `https://intelforhire.com/img/meta-developer.png` },
    ]
  }),
/*  title: function () {
    return 'Intel for hire :: Full Stack Developer'
  },
  meta: function () {
    return {
      title: 'Full Stack Developer (with data skills)',
      description: 'Intel for hire, Inc., a California-based company, is looking for a talented, resourceful, junior-level Full Stack Developer (with some data skills) in Argentina or elsewhere in South America.',
      url: `${document.location}`,
      image: `${document.location}/img/meta-developer.png`
    }
  },*/
  components: {
    // HelloWorld
  }
}

</script>
<style type="text/css" scoped>
ul {
  text-indent: -20px;
  margin-left: 20px;
}

li::marker {
  content: "👉🏻";
}

</style>
