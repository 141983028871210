import Vue from 'vue'
// import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
// import titleMixin from './services/titleMixin'
// import metaMixin from './services/metaMixin'
// Vue.mixin(titleMixin)
// Vue.mixin(metaMixin)
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
})
Vue.use(VueGtag, {
  config: { id: 'UA-55345670-7' },
  enabled: (process.env.NODE_ENV !== 'development')
}, router)
/* createApp(App).use(VueGtag, {
  config: { id: 'UA-55345670-7' },
  enabled: (process.env.NODE_ENV !== 'development')
}).use(router).mount('#app')
*/
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
